<template>
  <v-btn
    :color="color"
    :type="type"
    :outlined="outlined"
    :disabled="disabled"
    @click="handleClick"
    :class="outlined ? 'btn-outlined' : 'btn-default'"
    :height="height"
    :width="width"
    depressed
  >
    <span :class="fontSize ? 'button-random' : 'label-button'">{{ label }}</span>
  </v-btn>
</template>

<script>
export default {
  props: {
    onClick: {
      type: Function,
      default: () => {},
    },
    label: {
      type: String,
      required: true,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: function () {
        return this.outlined ? '#8E8E93' : '#636366';
      },
    },
    height: {
      type: Number,
      default: 40,
    },
    width: {
      type: [Number, String],
      default: 100,
    },
    fontSize: {
      type: Number,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
    },
  },
  methods: {
    handleClick() {
      this.onClick();
    },
  },
};
</script>

<style scoped lang="scss">
.label-button {
  font-size: 16px;
}

.button-random{
  font-size: 13px;
}

.button-random, .label-button{
  text-transform: none;
  color: #ffffffe5;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15000000596046448px;
}

.btn-outlined {
  background: #1c1c1e;
}

.btn-default,
.btn-outlined {
  border-radius: 10px;
}

.btn-default{
  font-family: 'PUDSansserifB';
}

.theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
  background-color: #3a3a3c !important;
  pointer-events: all !important;

  .label-button {
    color: #636366;
  }
  &:hover {
    cursor: not-allowed;
  }
}
</style>
