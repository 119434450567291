<template>
  <div class="text-center">
    <v-dialog class="custorm-modal" :persistent="true" :value="true" @keydown.esc="handleModalOutSide" @click:outside="handleModalOutSide" :width="width">
      <v-card>
        <v-card-title v-if="titleHeader && titleHeader.length" class="card-title-modal">
          <div class="title-modal">
            {{ titleHeader }}
          </div>
          <div class="title-action">
            <slot name="action"></slot>
          </div>
        </v-card-title>
        <v-card-text>
          <slot></slot>
        </v-card-text>
        <v-card-actions
          :style="cardActionsStyle"
          :class="{
            'aciont-center': aciontCenter,
            'aciont-right': actionRight,
          }"
        >
          <ButtonComponent
            v-if="isShowBtnCancel"
            :width="widthButtonFooter"
            :height="56"
            :onClick="handleModalEventCancel"
            :label="btnCancel"
            :outlined="true"
          />
          <ButtonComponent
            v-if="isShowBtnSave"
            :width="widthButtonFooter"
            :height="56"
            :onClick="handleModalEventSave"
            :label="btnSave"
            :outlined="false"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ButtonComponent from '@/components/ButtonComponent.vue';

export default {
  props: {
    onModalSaveEvent: {
      type: Function,
      default: () => { },
    },
    onModalCancelEvent: {
      type: Function,
      default: () => { },
    },
    titleHeader: {
      type: String,
    },
    titleHeaderSelect: {
      type: Array,
    },
    btnCancel: {
      type: String,
      default: 'CANCEL',
    },
    btnSave: {
      type: String,
      default: 'SAVE',
    },
    width: {
      type: Number,
      default: 672,
    },
    widthButtonFooter: {
      type: Number,
      default: 257,
    },
    aciontCenter: {
      type: Boolean,
      default: false,
    },
    actionRight: {
      type: Boolean,
      default: false,
    },
    isShowBtnCancel: {
      type: Boolean,
      default: true,
    },
    isShowBtnSave: {
      type: Boolean,
      default: true,
    },
    paddingFooter: {
      type: Number,
    },
    persistent: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {};
  },
  methods: {
    handleModalOutSide(event) {
      if (event.key === 'Escape') {
        this.onModalCancelEvent();
      }
    },
    handleModalEventCancel() {
      this.onModalCancelEvent();
    },
    handleModalEventSave() {
      this.onModalSaveEvent();
    },
    addGlobalEscapeListener() {
      document.addEventListener('keydown', this.handleModalOutSide);
    },
    removeGlobalEscapeListener() {
      document.removeEventListener('keydown', this.handleModalOutSide);
    }
  },
  computed: {
    cardActionsStyle() {
      return {
        padding: this.paddingFooter ? this.paddingFooter + 'px' : '40px',
      };
    },
  },
  components: {
    ButtonComponent,
  },
  mounted() {
    this.addGlobalEscapeListener();
  },
  beforeDestroy() {
    this.removeGlobalEscapeListener();
  },
};
</script>

<style scoped>
.theme--light.v-application {
  background: none;
}
.v-dialog__content {
  background-color: rgba(0, 0, 0, 0.8);
}

.v-card.v-sheet.theme--light {
  background: #1c1c1e;
  color: #ffffff;
}

.v-dialog>.v-card>.v-card__title.card-title-modal {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #2c2c2e;
  padding: 12px 24px;
}

.v-dialog>.v-card>.v-card__title.card-title-modal .title-modal {
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0em;
}

.theme--light.v-card>.v-card__text {
  color: #ffffff;
  padding: 16px 24px 0 24px;
}

.v-dialog>.v-card>.v-card__actions {
  justify-content: space-between;
}

.v-dialog>.v-card>.v-card__actions.aciont-center {
  justify-content: center;
}

.v-dialog>.v-card>.v-card__actions.aciont-right {
  justify-content: flex-end;
  gap: 28px;
}

.aciont-full button {
  width: 100%;
}

.v-dialog {
  margin: 24px 40px !important;
}
</style>
